<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 190px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="商城名称" name="storeTitle" class="ui-form__item">
						<a-input v-model:value="formState.storeTitle" placeholder="请输入商城名称"/>
					</a-form-item>

					<a-form-item label="商品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入商品名称"/>
					</a-form-item>

					<a-form-item label="是否显示" name="isShow" class="ui-form__item">
						<a-select style="width: 190px;" v-model:value="formState.isShow" placeholder="请选择">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="0">否</a-select-option>
							<a-select-option :value="1">是</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="商品分类" name="classifyId">
						<div style="width: 190px;">
							<a-cascader
								v-model:value="formState.classifyId"
								:options="classifyList"
								:fieldNames="{
									label: 'title',
									value: 'id',
									children: 'children'
								}"
								placeholder="请选择商品分类"
							/>
						</div>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['mall_goods_cinema_add']" type="primary" @click="onAddCinemaGoods">新增</a-button>
						<a-button v-permission="['mall_goods_cinema_open']" :disabled="selectedRowKeys.length === 0" type="primary" ghost @click="onBatchDisabled(true)" style="margin-left: 20px;">批量上架</a-button>
						<a-button v-permission="['mall_goods_cinema_disabled']" :disabled="selectedRowKeys.length === 0" type="primary" ghost @click="onBatchDisabled(false)" style="margin-left: 20px;">批量下架</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<a-tabs v-model:activeKey="status" style="margin-top: 20px;" type="card" @change="onTabChange">
				<a-tab-pane :key="0" tab="全部"></a-tab-pane>
				<a-tab-pane :key="1" tab="出售中"></a-tab-pane>
				<a-tab-pane :key="2" tab="已售罄"></a-tab-pane>
				<a-tab-pane :key="3" tab="库存预警"></a-tab-pane>
				<a-tab-pane :key="4" tab="已下架"></a-tab-pane>
			</a-tabs>
			<a-table :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 3000 }">
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'goods'">
						<div>
							商品标题：<span style="color: #666;">{{ record.mainTitle }}</span>
						</div>
						<div>
							商品分类: <span style="color: #999;">{{ record.classifyName }}</span>
						</div>
					</template>

					<template v-if="column.key === 'img'">
						<a-image :src="record.imgUrl.split(',')[0]" style="width: 60px;"></a-image>
					</template>

					<template v-if="column.key === 'card'">
						<div v-if="record.isCard">
							<a-tag color="orange" v-if="record.cardType === 1">次卡</a-tag>
							<a-tag color="blue" v-else>年卡</a-tag>
							<div v-if="record.cardType === 1">观影次数：{{ record.cardBalanceTimes }}</div>
							<div>卡等级：{{ record.cardTitle }}</div>
							<div>
								有效期：
								<span v-if="record.validityType === 1">永不过期</span>
								<span v-else>
									<span v-if="record.validityType !== 5">
										{{ record.validityValue }} {{ ['年', '月', '日'][record.validityType - 2] }}
									</span>
									<span v-else>{{ transDateTime(record.validityValue, 1) }}</span>
								</span>
							</div>
						</div>
						<div v-if="record.isRechargeCard">
							<a-divider style="margin: 6px 0;" v-if="record.isCard"/>
							<a-tag color="cyan" style="color: #2db7f5;">线上会员卡</a-tag>
							<div v-if="record.rechargeCardTitle">卡等级：{{ record.rechargeCardTitle }}</div>
							<!-- <div>初始金额：{{ record.initAmount }}元</div> -->
						</div>
						<div v-if="!record.isCard && !record.isRechargeCard">--</div>
					</template>

					<template v-if="column.key === 'coupon'">
						<div v-if="record.isCoupon">
							<div v-if="record.ticketBatchNum">
								<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatch?.batchNo }}</span></div>
								兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatchNum }}张</span>
							</div>
							<div v-if="record.snackBatchNum">
								<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{ record.snackBatch?.batchNo }}</span></div>
								卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.snackBatchNum }}张</span>
							</div>
						</div>
						<span v-else>--</span>
					</template>

					<template v-if="column.key === 'part'">
						<div>
							<a>浏览量：{{ record.recordNum }}</a>
						</div>
						<div>
							<a>点赞数：{{ record.praiseNum }}</a>
						</div>
						<!-- <a>分享数：{{ record. }}</a> -->
						<!-- <a>评论数：{{ record. }}</a> -->
						<div>
							<a>收藏数：{{ record.collectNum }}</a>
						</div>
					</template>

					<template v-if="column.key === 'price'">
						<div>销售价：<span style="color: red;">{{ record.nowPrice }}</span>元</div>
						<div>成本价：<span style="color: rgb(20, 205, 114);">{{ record.costPrice }}</span>元</div>
						<div>市场价：<span style="color: rgb(24, 144, 255);">{{ record.originPrice }}</span>元</div>
					</template>
					<template v-if="column.key === 'stock'">
						<div>现有：<span>{{ record.stock }}</span></div>
						<div>预警：<span>{{ record.stockAlert }}</span></div>
					</template>
					<template v-if="column.key === 'sell'">
						<div>基础：<span style="color: rgb(252, 169, 89);">{{ record.virtualSoldNum }}</span></div>
						<div>实际：<span style="color: rgb(20, 205, 114);">{{ record.salesVolume }}</span></div>
					</template>

					<template v-if="column.key === 'deliveryType'">
						{{ ['无', '自提', '快递'][record.deliveryType - 1] }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-else-if="column.key === 'isShelf'">
						{{ record.isShelf === 0 ? '已下架' : '已上架' }}
					</template>
					<template v-else-if="column.key === 'isShow'">
						{{ record.isShow ? '显示' : '不显示' }}
					</template>
					<template v-else-if="column.key === 'isShare'">
						{{ record.isShare ? '允许' : '不允许' }}
					</template>
					<template v-else-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div @click="onSee(record)">
										<a-menu-item>
											查看
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_edit']" v-if="record.isShelf === 0" @click="onEdit(record)">
										<a-menu-item>
											编辑
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_disabled']" v-if="record.isShelf === 1" @click="onDisabled(record)">
										<a-menu-item>
											下架
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_open']" v-if="record.isShelf === 0" @click="onDisabled(record)">
										<a-menu-item>
											上架
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_show']" v-if="record.isShow === 0" @click="onShow(record)">
										<a-menu-item>
											显示商品
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_show']" v-else @click="onShow(record)">
										<a-menu-item>
											不显示商品
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_share']" @click="onShare(record, 1)" v-if="$store.state.cinema.uiVersion == 1">
										<a-menu-item>
											微信推广商品
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_share']" @click="onShare(record, 2)">
										<a-menu-item>
											抖音推广商品
										</a-menu-item>
									</div>
									<div v-permission="['mall_goods_cinema_delete']" v-if="record.isShelf === 0" @click="onDelete(record)">
										<a-menu-item>
											删除
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>

			<a-modal v-model:visible="showShare" width="600px" title="商品推广二维码">
				<template #footer>
					<a-button type="primary" @click="showShare = false">关闭</a-button>
				</template>
				<div style="text-align: center;">
					<a-image :src="imageUrl" style="width: 300px;height: 300px;"></a-image>
					<p style="margin-top: 40px;">点击鼠标右键保存二维码</p>
				</div>
			</a-modal>
		</a-spin>
	</div>
	<publish v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" :type="type" form="store" @back="onBack"></publish>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import publish from '@/views/mall/goods/publish/publish.vue';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import { getShareQR } from '@/service/modules/advert.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getStoreGoodsList, pullOnShelvesStoreGoods, pullOffShelvesStoreGoods, batchPullOnShelvesStoreGoods, batchPullOffShelvesStoreGoods, storeGoodsDelete, changeIsShow } from '@/service/modules/goods.js';
  import {getCinemaSetting} from "@/service/modules/system";
	export default {
		components: { Icon, publish },
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				classifyList: [],
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					isShow: -1,
				},
				status: 0,
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				},{
					title: '商城名称',
					dataIndex: 'storeTitle'
				}, {
					title: '商品信息',
					key: 'goods'
				}, {
					title: '商品图片',
					key: 'img',
					width: 130
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '券信息',
					key: 'coupon'
				}, {
					title: '价格信息',
					key: 'price',
					width: 180
				}, {
					title: '参与记录信息',
					key: 'part',
					width: 180
				}, {
					title: '销量',
					key: 'sell',
					width: 180
				}, {
					title: '库存信息',
					key: 'stock',
					width: 180
				}, {
					title: '配送方式',
					key: 'deliveryType',
					width: 150
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 200
				}, {
					title: '状态',
					key: 'isShelf',
					width: 100
				}, {
					title: '是否显示',
					key: 'isShow',
					width: 100
				}, {
					title: '是否允许分享',
					key: 'isShare',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				isEdit: true,
				isSee: false,
				id: 0,
				type: 2,
				showShare:false,
				imageUrl: '',
        uiVersion: 1,
			}
		},
		created() {
			this.getOrganizationList();
			this.getGoodsAllClassifyList();
      this.getCinemaSetting();
			// this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.isShow = this.searchData.isShow >= 0 ? this.searchData.isShow : undefined;
				if (this.searchData.classifyId && this.searchData.classifyId.length) {
					this.searchData.classifyId = this.searchData.classifyId[this.searchData.classifyId.length - 1];
				} else {
					this.searchData.classifyId = undefined;
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getStoreGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						status: this.status,
						saleType: 1,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getGoodsAllClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 1
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onTabChange() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				this.getData();
			},
      onAddCinemaGoods() {
        this.type = 2;
        this.isEdit = false;
        this.isSee = false;
        this.showModal = true;
      },
			onEdit(item) {
        this.isEdit = true;
				this.id = item.id;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isSee = true;
        this.isEdit = false;
				this.showModal = true;
			},
			onShow(item) {
				// changeIsShow
				this.$confirm({
					title: '提示',
					content: '确定在移动端' + (item.isShow ? '不显示' : '显示') + '该商品吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							ret = await changeIsShow({
								id: item.id,
								isShow: item.isShow ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isShelf ? '下架' : '上架') + '该商品吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (item.isShelf === 0) {
								ret = await pullOnShelvesStoreGoods({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesStoreGoods({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isShelf ? '下架' : '上架') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDisabled(type) {
				this.$confirm({
					title: '提示',
					content: '确定批量' + (type ? '上架' : '下架') + '该商品吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (type) {
								ret = await batchPullOnShelvesStoreGoods({
									ids: this.selectedRowKeys.join(',')
								})
							} else {
								ret = await batchPullOffShelvesStoreGoods({
									ids: this.selectedRowKeys.join(',')
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.$message.success(( type ? '批量上架' : '批量下架') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该商品吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await storeGoodsDelete({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			async onShare(item, source) {
				this.loading = true;
				try {
					let ret = await getShareQR({
						page: 'pages/enterIndex/index',
						scene: `path=6&cId=${item.cinemaId}&id=${item.id}`,
						source
					})
					this.loading = false;
					if (ret.code === 200) {
						this.imageUrl = ret.data.shareQR;
						this.showShare = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
      async getCinemaSetting() {
        let ret = await getCinemaSetting({});
        if (ret.code === 200) {
          this.uiVersion = ret.data.uiVersion;
          // this.$store.commit("cinema/setUiVersion", ret.data.uiVersion);
          // this.modelRef = ret.data;
        }
      }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
